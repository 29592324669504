import styles from './public-frontpage-slider.scss';

class FrontpageSlider extends HTMLElement {
    constructor() {
        super();
        //console.log("Constructed");

        const supportsDeclarative = HTMLElement.prototype.hasOwnProperty("attachInternals");
        const internals = supportsDeclarative ? this.attachInternals() : undefined;


        this.shadow = internals?.shadowRoot;



    }
    init() {
        this.currentSlideIndex = 0;
        this.currentActiveDot = 0;
        this.slideWrapper = this.shadow.querySelector('.slides-container');
        this.backButton = this.shadow.querySelector('.back-button');
        this.forwardButton = this.shadow.querySelector('.forward-button');
        this.slides = this.shadow.querySelectorAll('.slide');
        this.dots = this.shadow.querySelectorAll('.progress-dot');
        this.dotsInner = this.shadow.querySelector('.dots-inner-wrapper');
        this.setEvents();
    }
    setEvents() {
        document.addEventListener('keydown', event => {
            if (event.code === 'ArrowRight') {
                this.goForward();
            } else if (event.code === 'ArrowLeft') {
                this.goBack();
            };
        })

        window.addEventListener('resize', () => {
            this.slideWidth = this.slides[0].offsetWidth;
            reportHeightToParent();
        });

        this.slideWrapper.addEventListener('scroll', (evt) => {
            const index = Math.round(this.slideWrapper.scrollLeft / this.slideWrapper.offsetWidth);
            if (index !== this.currentSlideIndex) {
                this.setProgress();
            }
        });

        this.slideWrapper.addEventListener('scrollend', (evt) => {
            this.setProgress();
        });


        this.backButton.addEventListener('click', () => {
            this.goBack();
        });

        this.forwardButton.addEventListener('click', () => {
            this.goForward();
        });

        const messageActions = {
            textSliderPreviousSlide: () => this.goBack(),
            textSliderNextSlide: () => this.goForward(),
            textSliderShowSlide: (evt) => {
                if (!evt.data) return;
                this.gotoSlide(evt.data.index, !!evt.data.skipAnimation)
            },
        };

        window.addEventListener('message', (evt) => {
            const call = evt?.data?.call;
            const action = messageActions[call];

            if (typeof call === "string" && typeof action === "function") {
                action(evt);
            }
        });

        //onFirstVisible(this.element).then(() => this.element.dispatchEvent(new CustomEvent("firstVisible")));
    }
    gotoSlide(newSlideIndex, instant = false) {


        console.log("Goto slide", newSlideIndex, instant);
        this.currentSlideIndex = newSlideIndex;

        this.slideWrapper.scrollTo({
            left: newSlideIndex * this.slideWrapper.offsetWidth,
            behavior: instant ? 'auto' : 'smooth'
        });
        this.setProgress();

        this.element.dispatchEvent(new CustomEvent("change", {
            detail: {
                index: this.currentSlideIndex
            }
        }));
    }

    goBack() {
        if (this.currentSlideIndex > 0) {
            this.gotoSlide(this.currentSlideIndex - 1);
        }
    }

    goForward() {
        if (this.currentSlideIndex < this.slides.length) {
            this.gotoSlide(this.currentSlideIndex + 1);
        }
    }
    setProgress() {
        const index = Math.round(this.slideWrapper.scrollLeft / this.slideWrapper.offsetWidth);



        if (index === 0) {
            this.backButton.classList.add('hidden');
        } else {
            this.backButton.classList.remove('hidden');
        }

        if (index === (this.slides.length - 1)) {
            this.forwardButton.classList.add('hidden');
        } else {
            this.forwardButton.classList.remove('hidden');
        }
        if (window.parent !== window) {
            window.parent.postMessage({ call: 'textSliderIndexChange', index }, '*');
        }
        this.updateDots(index)
    }
    updateDots(index) {
        this.currentSlideIndex = index;

        const newActiveDot = Math.round(this.slideWrapper.scrollLeft / this.slideWrapper.offsetWidth);
        if (this.currentActiveDot != newActiveDot) {
            this.dots[this.currentActiveDot].classList.remove('progress-dot-active')
            this.dots[newActiveDot].classList.add('progress-dot-active')
            this.currentActiveDot = newActiveDot;
        }
        const offSet = Math.max(this.currentActiveDot - 3, 0);
        if (offSet === 0) {
            this.dotsInner.classList.add('dots-initial')
        } else {
            this.dotsInner.classList.remove('dots-initial')
        }
        const style = (offSet * -10) + 'px';
        this.dotsInner.style.transform = `translateX(${style})`;
    }
    connectedCallback() {
        console.log("Connected");

        this.init()
    }

}

customElements.define("drn-frontpage-slider", FrontpageSlider);